import React from "react";

import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";
import NotFound from "../../components/sections/not-found";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Navigation background="white" />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
